import { inject, Inject, Injectable } from '@angular/core';

import { firstValueFrom } from 'rxjs';

import { IBrowserInfo } from '@interfaces';

import { ApiService } from '@services/api/api.service';

import { IS_SERVER } from '@lib/tokens';
import { BROWSER_MIN_VERSIONS } from '@lib/constants';

@Injectable({
  providedIn: 'root',
})
export class BrowserInfoService {
  static isMobile = false;

  static checkIsMobile(): boolean {
    if (typeof navigator === 'undefined') {
      this.isMobile = false;
      return false;
    }

    const userAgent = navigator?.userAgent ?? '';

    const isMobile = !!(
      userAgent.match(/Android/i) ||
      userAgent.match(/webOS/i) ||
      userAgent.match(/iPhone/i) ||
      userAgent.match(/iPad/i) ||
      userAgent.match(/iPod/i) ||
      userAgent.match(/Windows Phone/i)
    );

    this.isMobile = isMobile;
    return isMobile;
  }

  static get isSmartphone(): boolean {
    if (!this.isMobile || !('matchMedia' in window)) {
      return false;
    }

    return window.matchMedia('(orientation:portrait)').matches;
  }

  public browserInfo: IBrowserInfo = { name: '', version: 1000, ua: '', os: '', mobile: null };
  private isServer = inject(IS_SERVER);
  private api = inject(ApiService);

  constructor() {
    BrowserInfoService.checkIsMobile();
  }

  public async init(): Promise<void> {
    if (this.isServer || typeof navigator === 'undefined') {
      return;
    }

    const response = await firstValueFrom(this.api.post<IBrowserInfo>('users/ua', null));

    if (!response?.payload || !Object.keys(response.payload).length) {
      return;
    }

    this.browserInfo = response.payload;
    BrowserInfoService.isMobile = response.payload.mobile ?? false;
  }

  get fullName(): string {
    return `${this.browserInfo.name} ${this.browserInfo.version}`;
  }

  get isMobile(): boolean {
    if (this.isServer) {
      return false;
    }

    return this.browserInfo.mobile ?? BrowserInfoService.checkIsMobile();
  }

  get isSmartphone(): boolean {
    BrowserInfoService.checkIsMobile();
    return BrowserInfoService.isSmartphone;
  }

  get satisfiesMinVersion(): boolean {
    const { name, version } = this.browserInfo;
    const minVersion = BROWSER_MIN_VERSIONS[name?.toLowerCase()] || 0;

    return version >= minVersion;
  }

  get isFirefox(): boolean {
    return this.browserInfo.name === 'Firefox';
  }

  get isSafari(): boolean {
    try {
      return navigator?.vendor?.toLowerCase()?.includes('apple');
    } catch (e) {
      return this.browserInfo.name.toLowerCase().includes('safari');
    }
  }

  get isFacebook(): boolean {
    const userAgent = this.browserInfo?.ua?.toLowerCase();
    const fbIdentifiers = ['fb_iab', 'fbav', 'fba4'];

    return fbIdentifiers.some(identifier => userAgent.includes(identifier));
  }
}
